import { graphql } from "gatsby"
import styled from 'styled-components';
import PropTypes from "prop-types"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import DefaultHero from '../components/default-hero';
import Sidebar from '../components/sidebar';

import YoastSeoParts from '../fragments/seo';

class PageTemplate extends Component {

  render() {
    const page = this.props.data.wpPage;
    const { seo } = page
    const mostRecent = this.props.data.mostRecent.nodes;
    const { opportunities, pageAccentColor } = page.template.templateCareerOpportunities;

    return (
      <Layout>
        <PageWrapper accentColor={pageAccentColor || '#21c6be'}>
          <Head title={seo.title} description={seo.metaDesc} meta={seo} />

          <DefaultHero title={page.title} backgroundColor="#65869C" />

          <div className="container baseContainer" style={{ paddingTop: '20px' }}>
            <div className="grid smallGrid">
              <div className="col-md-8 baseContainerLeft maincopy">
                <div dangerouslySetInnerHTML={{ __html: opportunities }}></div>
              </div>
              <div className="col-md-4 baseContainerRight">
                <Sidebar posts={mostRecent} />
              </div>
            </div>
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

const PageWrapper = styled.div`
  .defaultHero .dots span {
    color: ${({ accentColor }) => accentColor};
  }

  .defaultHero {
    border-top-color: ${({ accentColor }) => accentColor};
  }
`

export default PageTemplate

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      date
      title
      seo {
        ...seoParts
      }
      template {
        ... on WpTemplate_CareerOpportunities {
          templateName
          templateCareerOpportunities {
            eeoStatement
            pageAccentColor
            fieldGroupName
            opportunities
            opportunitiesCta {
              target
              title
              url
            }
          }
        }
      }
    }

    mostRecent: allWpPost (sort: {fields: date, order: DESC}, limit: 5) {
      nodes {
        id
        title
        link
        excerpt
        date(formatString: "MMMM DD, YYYY")
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`