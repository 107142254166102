import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import '../css/sidebar.css';

import placeholder from "../images/default-blog.jpg";

const Sidebar = ({ posts }) => {

  const categoryList = useStaticQuery(graphql`
    {
      allWpCategory {
        nodes {
          id
          wpURI
          name
        }
      }
    }
  `)

  const categories = categoryList.allWpCategory.nodes;

  return (
    <React.Fragment>
      <p className="blogSidebarTitle">Most Recent</p>

      { posts &&
        posts.map(post => {
          const { id, title, link, date, excerpt, featuredImage, categories } = post;
          let divStyles = { 'backgroundImage' : 'url('+placeholder+')' }

          if(featuredImage && featuredImage.node) {
            divStyles.backgroundImage = 'url('+featuredImage.node.localFile.publicURL+')';
          }

          return (
            <div key={id} className="grid grid-bleed align-center blogGrid">
              <div className="col-sm-4">
                <a href={link}>
                  <div className="sidebarImage" style={divStyles}></div>
                </a>
              </div>
              <div className="col-sm-8">
                {/* <p className="bDate">{date}</p> */}
                <a href={link} className="bTitle">{title}</a>
              </div>
            </div>
          )
        })
      }

      <hr className="sidebarDivider" />
      <p className="blogSidebarTitle">Categories</p>
      <br />

      <div className="sidebar-categories">
        
          { categories &&
            categories.map(post => {
              const { id, name, wpURI } = post;
              return (<div key={id} className=""><Link className="catName" to={wpURI}>{name}</Link></div>)
            })
          }
        
      </div>


    </React.Fragment>
  )
}

export default Sidebar